import { useTranslation } from 'hooks/useTranslation'
import Head from 'next/head'
import React, { FC } from 'react'

type MetadataProps = {
  title?: string
  description?: string
  keywords?: string[]
  image?: string
  url: string
}

export const Metadata: FC<MetadataProps> = ({ title, description, keywords, image, url }: MetadataProps) => {
  const { t } = useTranslation()
  const currentImage = image || '/images/logo.svg'

  const currentDescription = description || t('common:metadata.description')
  const currentKeywords = keywords?.join('') || t('common:metadata.keywords')
  const currentTitle = title || t('common:metadata.title')

  return (
    <Head>
      <title>{currentTitle}</title>
      <meta name="description" content={currentDescription} />
      <meta name="keywords" content={currentKeywords} />
      <meta name="robots" content="index, follow" />
      <meta property="og:title" content={currentTitle} />
      <meta property="og:description" content={currentDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={currentImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={currentTitle} />
      <meta name="twitter:description" content={currentDescription} />
      <meta name="twitter:image" content={currentImage} />
      <link rel="canonical" href={url} />
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'WebPage',
          name: currentTitle,
          description: currentDescription,
          url,
        })}
      </script>
    </Head>
  )
}
